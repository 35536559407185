import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "constants/AuthConstant";

const AutoLogin = () => {
  const navigate = useNavigate();
  const { queryToken } = useParams();
  const { token } = useSelector((state) => state.auth);
  const parts = queryToken?.split(".") || [];

  useEffect(() => {
    if (token || !queryToken) {
      navigate("/");
      return;
    }
    if (parts.length !== 3) {
      localStorage.setItem(AUTH_TOKEN, "");
      navigate("/");
    } else {
      localStorage.setItem(AUTH_TOKEN, queryToken);
      window.location.reload();
    }
  }, [queryToken, parts, token, navigate]);

  return <div>...</div>;
};

export default AutoLogin;
