import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Yakutya';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const BASE_URL = env.BASE_URL

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
export const AUTHENTICATED_TOUR_ENTRY = `${APP_PREFIX_PATH}/apps/tours/tour-list`;
export const AUTHENTICATED_HOTEL_ENTRY = `${APP_PREFIX_PATH}/apps/hotels/hotel-list`;
export const AUTHENTICATED_RESTAURANT_ENTRY = `${APP_PREFIX_PATH}/apps/restaurant/list-restaurant`;
export const AUTHENTICATED_REGION_ENTRY = `${APP_PREFIX_PATH}/apps/region/list`;


export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
